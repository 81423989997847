import React, {useEffect} from "react";
import config from "../config/main.config"
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import {UseWindowSize, MAX_MOBILE_WIDTH, jumpToTop} from "../helper/util";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import EditHTMLComponent from "../components/editHTML/EditHTMLComponent";
import {loadTextsByNamespace} from "../../server/helper/ssrHelper";
import EditTextComponent from "../components/editText/EditTextComponent";
import EditImageComponent from "../components/editImage/EditImageComponent";
import i18n from "../i18n";
import history from "../helper/browserHistory";
import ImageCarouselComponent from "../components/editImage/ImageCarouselComponent";
import userStore from "../components/userManagement/userStore";

const Home = () => {
    let applicationWidth = UseWindowSize().width;

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);
    const namespace = 'home';

    return (
        <div className={'grid ui'}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'home', url: '/'}]}/>
            </div>

            <div className={"top-image"}
                 style={{backgroundImage: "url(" + config.BASE_URL_IMAGES + "Landingpage_Andreas_Scholz.webp)",}}/>
            <div className={'row centered'} style={{marginBottom: '200px'}}>
                <div className={'twelve wide column page-content-wrapper'}
                     style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                         position: 'absolute',
                         bottom: '-150px',
                         padding: 0
                     } : {}}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position'} style={{float: 'left', minWidth: '50%'}}>
                            <Card.Header className={'card-header'} s>
                                <h1 style={{paddingBottom: "45px"}}><EditTextComponent id={`${namespace}-card-1-header`}
                                                                                       namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0', padding: '1rem 0 0 0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-card-1-text`}
                                                       namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-1-header`} namespace={namespace}/>
                        </h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-card-1-text`}
                                                   namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered page-content-wrapper'}
                 style={applicationWidth <= MAX_MOBILE_WIDTH ? {rowGap: '4rem', paddingTop: '4rem'} : {}}>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/></p>
                    <button className={'button-1'} onClick={() => {
                        history.push('/' + i18n.language.replace('_', '-') + '/stationär')
                    }}>STATIONÄRE PFLEGE >
                    </button>

                </div>
                <div className={'eight wide column '}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-1`}/>
                </div>
                <div className={'eight wide column '}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-2`}/>
                </div>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-2`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-2`} namespace={namespace}/></p>
                    <button className={'button-1'}
                            onClick={() => history.push('/' + i18n.language + '/ambulant')}>AMBULANTE
                        BETREUUNG >
                    </button>
                </div>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-3`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-3`} namespace={namespace}/></p>
                    {/*<button className={'button-1'} onClick={_ => {
                        history.push("/" + i18n.language + "/angebote")
                    }}>ZU DEN ANGEBOTEN ></button>*/}
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-3`}/>
                </div>
            </div>
            <div style={{display: 'flex', width: '100%', position: 'relative', bottom: '-325px'}}>
                <svg width='800' height='8' style={{margin: 'auto'}}>
                    <line x1='0' y1='0' x2='100%' y2='0' stroke='white' strokeWidth='8'/>
                </svg>
            </div>
            <div className={'grid ui'} style={{width: '101%', backgroundColor: '#1272a1'}}>
                <img src={'/images/bows/Bogen2_Footer_Hospiz_Weiss.svg'} alt={'bow'}
                     style={{position: 'absolute', padding: '0', zIndex: '1', left: 0}}/>
                <div className={'row centered page-content-wrapper'}>
                    <div className={'sixteen wide column'}
                         style={{paddingTop: '20rem', zIndex: '10'}}>
                        <h1 className={'quote-heading'}>
                            <EditTextComponent id={`${namespace}-quote-header`} namespace={namespace}/>
                        </h1>
                        <div className={'quote-container'}>
                            <ImageCarouselComponent id={'quote-carousel'}
                                                    type={'text'}
                                                    showButtons={false}
                                                    showDots={true}
                                                    infinite={true}
                                                    isPlaying={true}
                                                    interval={4000}
                                                    naturalSlideWidth={.5}
                                                    naturalSlideHeight={applicationWidth <= MAX_MOBILE_WIDTH ? .3 : .15}/>
                        </div>
                    </div>
                    <div className={'sixteen wide computer fourteen wide mobile column'} style={{bottom: '-200px'}}>
                        <Card className={'card-container'}>
                            <Card.Content>
                                <div className={'grid ui'}>
                                    <div className={'row centered'}
                                         style={applicationWidth <= MAX_MOBILE_WIDTH ? {} : {paddingTop: '6rem'}}>
                                        <div className={'twelve wide computer fifteen wide mobile column'}
                                             style={{textAlign: 'center', marginBottom: '8rem'}}>
                                            <h1><EditTextComponent id={`${namespace}-card-2-header`}
                                                                   namespace={namespace}/></h1>
                                            <p style={applicationWidth <= MAX_MOBILE_WIDTH ? {} : {
                                                paddingLeft: '4rem',
                                                paddingRight: '4rem'
                                            }}>
                                                <EditTextComponent id={`${namespace}-card-2-subheader`}
                                                                   namespace={namespace}/>
                                            </p>
                                        </div>
                                        {/*<div
                                            className={'five wide computer fifteen wide mobile column icon-card-container'}
                                            style={{display: 'grid'}}>
                                            <div className={'icon-card-container'}>
                                                <EditImageComponent id={`${namespace}-image-4`}
                                                                    style={{width: '100%'}}/>
                                            </div>
                                            <p onClick={_ => !userStore.isAdmin ? history.push('/' + i18n.language + '/ehrenamt') : null}
                                               className={'icon-text'}><EditTextComponent
                                                id={`${namespace}-card-2-image-header-1`}
                                                namespace={namespace}/>
                                            </p>
                                        </div>*/}
                                        <div
                                            className={'eight wide computer fifteen wide mobile column icon-card-container'}
                                            style={{display: 'grid'}}>

                                            <div className={'icon-card-container'}>
                                                <EditImageComponent id={`${namespace}-image-5`}
                                                                    style={{width: '100%'}}/>
                                            </div>
                                            <a rel={'noreferrer'} target={'_blank'} style={{color: 'black'}}
                                               href={config.URL + 'downloads/Hospiz_Mitglied_Formular.pdf'}>
                                                <p className={'icon-text'}><EditTextComponent
                                                    id={`${namespace}-card-2-image-header-2`}
                                                    namespace={namespace}/>
                                                </p>
                                            </a>
                                        </div>
                                        <div
                                            className={'eight wide computer fifteen wide mobile column icon-card-container'}
                                            style={{display: 'grid'}}>
                                            <div className={'icon-card-container'}>
                                                <EditImageComponent id={`${namespace}-image-6`}
                                                                    style={{width: '100%'}}/>
                                            </div>
                                            <p onClick={_ => !userStore.isAdmin ? history.push('/' + i18n.language + '/donate') : null}
                                               className={'icon-text'}><EditTextComponent
                                                id={`${namespace}-card-2-image-header-3`}
                                                namespace={namespace}/>
                                            </p>
                                        </div>
                                        <svg width='900' height='6' style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                                            marginTop: '8rem',
                                            marginBottom: '8rem'
                                        } : {marginTop: '1rem', marginBottom: '1rem'}}>
                                            <line x1='0' y1='0' x2='900' y2='0' stroke='orange' strokeWidth='6'/>
                                        </svg>
                                        <div className={'twelve wide computer fifteen wide mobile column'}
                                             style={{textAlign: 'center'}}>
                                            <h1><EditTextComponent id={`${namespace}-card-2-subheader-2`}
                                                                   namespace={namespace}/></h1>
                                            <p className={'text'}
                                               style={applicationWidth <= MAX_MOBILE_WIDTH ? {} : {
                                                   paddingLeft: '25%',
                                                   paddingRight: '25%',
                                                   marginTop: '2rem'
                                               }}><EditTextComponent id={`${namespace}-card-2-text-1`}
                                                                     namespace={namespace}/></p>
                                        </div>
                                    </div>
                                    <div className={'row centered '}>
                                        <div
                                            className={'twelve wide computer fifteen wide mobile column margin-auto'}>
                                            <ImageCarouselComponent id={'partner-carousel'}
                                                                    style={{color: "black"}}
                                                                    showButtons={false}
                                                                    showDots={true}
                                                                    infinite={true}
                                                                    isPlaying={true}
                                                                    interval={4000}
                                                                    naturalSlideWidth={1}
                                                                    naturalSlideHeight={applicationWidth <= MAX_MOBILE_WIDTH ? .5 : .5}/>


                                            {/*<div className={'icon-card-container'}>
                                                <EditImageComponent style={{width: '100%'}} id={`${namespace}-image-7`}
                                                                    linkTo={'https://www.dhpv.de/start.html'}/>
                                            </div>
                                                <div className={'icon-card-container'}>
                                                <EditImageComponent style={{width:'100%'}} id={`${namespace}-image-8`} linkTo={'https://hpvbw.de/'}/>
                                                </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

Home.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'home').then((text) => {
        return text;
    })
}

export default Home