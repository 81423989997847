import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";


import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import userStore from "../userManagement/userStore";
import config from "../../config/main.config";
import history from "../../helper/browserHistory";

import {fetchAllImages, getFromLocalStorage} from "../../helper/util";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import languages from "../../../shared/activeLanguages.json";
import {Label} from "semantic-ui-react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";


function addSelectedImages(images, setIsSelectModalOpen, frontendId, setImages) {

    let linksToPut = images;
    fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(allImages => {
                //  ＼（〇_ｏ）／
                if (allImages.length > 0) {
                    for (let i = 0; i < allImages.length; i++) {
                        for (let j = 0; j < linksToPut.length; j++) {
                            if (linksToPut[j].link === allImages[i].link) {
                                linksToPut.splice(j, 1);
                            }
                        }
                    }
                }
                setImages(allImages.concat(linksToPut))
                if (linksToPut.length > 0) {
                    //put links to component
                    fetch(config.BASE_URL + 'admin/images/selection/', {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + getFromLocalStorage("token")
                        },
                        body: JSON.stringify({frontendId: frontendId, links: linksToPut})
                    }).then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            setIsSelectModalOpen(false);
                        }
                    }).catch(() => {
                    });
                } else {
                    setIsSelectModalOpen(false);
                }
            })
        }
    }).catch(e => {
        console.error("ERROR: ", e);
    })
}

function deleteTextItem(id) {
    return new Promise(resolve => fetch(config.BASE_URL + 'texts/' + id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json)
            })
        }
    }).catch((e) => {
        console.error(e)
    }))
}

function updateTextItem(item) {
    return new Promise(resolve => fetch(config.BASE_URL + 'texts/', {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify(item)
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(item.index)
            })
        }
    }).catch((e) => {
        console.error(e)
    }))
}

function createNewTextItem(text) {
    return new Promise(resolve => fetch(config.BASE_URL + 'texts/', {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify(text)
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json)
            })
        }
    }).catch((e) => {
        console.error(e)
    }))
}

function getSliderItems(id) {
    return new Promise(resolve => fetch(config.BASE_URL + 'texts/sliderItems/' + id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        }
    }).catch(() => {

    }))
}


function fetchImagesFrontendId(setImages, frontendId, setSelectImage, selectImage) {
    fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                for (let i = 0; i < json.length; i++) {
                    json[i].selected = json[i].frontendId === frontendId;
                    for (let j = 0; j < selectImage.length; j++) {
                        if (json[i].link === selectImage[j].link) {
                            let newArray = selectImage;
                            newArray[j].selected = true;
                            setSelectImage(newArray);
                        }
                    }
                }
                setImages(json);
            });

        } else {
            console.error(response.status)
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
            }
        }
    );
}

function deleteImage(link, id, setImage, setSelectImage, selectImage) {
    fetch(config.BASE_URL + "images/", {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({link: link, id: id})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            fetchImagesFrontendId(setImage, id, setSelectImage, selectImage);
        } else {
            console.error(response.status)
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
            }
        }
    );
}

function getGalleryItems(images) {
    let items = [];
    for (let i = 0; i < images.length; i++) {
        items.push(
            <Slide index={i} key={i}>
                <img
                    onClick={_ => images[i].name !== null && typeof window !== "undefined" ? window.open(images[i].name, '_newtab') : {}}
                    alt={images[i].alt ? images[i].alt : "Carousel Item " + i}
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '70%',
                        padding: "2rem"
                    }}
                    src={config.BASE_URL_IMAGES + 'all-images/' + images[i].link}/>
                <p style={{textAlign: 'center'}}>{images[i].alt}</p>
            </Slide>
        )
    }
    return items;
}

function GetTextItems(texts, displayType, setIsEditTextItem, setItemToEdit, setTexts, id) {
    let items = [];

    for (let i = 0; i < texts.length; i++) {
        items.push(
            <Slide index={i} key={i}>
                {displayType === "admin" ?
                    <Button.Group>
                        <Button color={'orange'} icon={'edit'} onClick={_ => {
                            setItemToEdit({...texts[i], index: i})
                            setIsEditTextItem(true)
                        }}/>
                        <Button floated={'right'} color={'red'} icon={'trash'} onClick={_ => {
                            deleteTextItem(texts[i].id).then(res => {
                                getSliderItems(id).then(allTexts => {
                                    setTexts(allTexts);
                                })
                            })
                        }}/>
                    </Button.Group> : null}
                <h1 className={'quote-author'}>{texts[i].title}</h1>
                <svg width='150' height='8'>
                    <line x1='0' y1='0' x2='150' y2='0' stroke='orange' strokeWidth='8'/>
                </svg>
                <p className={'quote-text'}>{texts[i].content}</p>
            </Slide>
        )
    }
    return items;
}

function uploadThumbnailImage(event, frontendId, images, setSelectModalOpen, setImages, setSelectImage) {
    const data = new FormData();

    let files = Array.from(event.target.files);

    //Send files
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("images", file);
    }
    fetch(config.BASE_URL + 'admin/images/upload-images/' + frontendId, {
        method: "POST",
        body: data,
        headers: {
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            addSelectedImages(images, setSelectModalOpen, frontendId, setImages);
            fetchImagesFrontendId(setImages, frontendId, setSelectImage, images);
        }
    }).catch(() => {

    });

}

function editCarouselImage(currentImage, setImages, setSelectImage, images, id) {
    return new Promise(resolve => fetch(config.BASE_URL + 'images/carousel', {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify(currentImage)
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            fetchImagesFrontendId(setImages, id, setSelectImage, images);
            return resolve(response);
        }
    }).catch(() => {

    }));
}

const ImageCarouselComponent = (props) => {
    const {
        id,
        t,
        naturalSlideWidth,
        naturalSlideHeight,
        interval,
        infinite,
        isPlaying,
        showButtons,
        showDots,
        style,
        type
    } = props;
    const [images, setImages] = useState([]);
    const [selectImage, setSelectImage] = useState([]);
    const [currentImage, setCurrentImage] = useState({});
    const [prevSelectImage, setPrevSelectImage] = useState([]);
    const [isSelectImageModalOpen, setIsSelectImageModalOpen] = useState(false);
    const [addNewText, setAddNewText] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [newTextItem, setNewTextItem] = useState({
        description: '',
        namespace: 'slider-item',
        language: ''
    });
    const [texts, setTexts] = useState([])
    const [isEditTextItem, setIsEditTextItem] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({})

    useEffect(_ => {
        if (type !== "text") {
            fetchAllImages().then(images => {
                setSelectImage(images);
                setPrevSelectImage(images);
                fetchImagesFrontendId(setImages, id, setSelectImage, images);
            });
        } else {
            getSliderItems(id).then(allTexts => {
                setTexts(allTexts);
                setNewTextItem({...newTextItem, frontendId: `${id}-item-text-${allTexts.length}`})
            })
        }


    }, []);


    const EditImageModal = () => {
        return (
            <Modal open={openEdit}>
                <Modal.Header>Bild bearbeiten</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label className={'field'}>Beschreibung</label>
                            <input value={currentImage.alt} onChange={(e) => {
                                setCurrentImage({...currentImage, alt: e.target.value})
                            }}/>
                        </Form.Field>
                        <Form.Field>
                            <label className={'field'}>Link</label>
                            <input value={currentImage.name} onChange={(e) => {
                                setCurrentImage({...currentImage, name: e.target.value})
                            }}/>
                        </Form.Field>


                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color={'green'} onClick={_ => {
                        editCarouselImage(currentImage, setImages, setSelectImage, images, id).then(res => {
                            setOpenEdit(false);
                        });
                    }}>{t("common:common.save")}</Button>
                    <Button color={'red'} onClick={_ => {
                        setOpenEdit(false)
                    }}>{t("common:common.cancel")}</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    const showImageSlides = getGalleryItems(images);
    let showImages = images.map((image, index) =>
        <Grid.Column key={index} width={5}>
            <Button.Group style={{width: '100%'}}>
                <Button
                    negative
                    onClick={() => {
                        if (image.hasOwnProperty("selected")) {
                            image.selected = false;
                        }
                        deleteImage(image.link, id, setImages, setSelectImage, selectImage);
                    }}
                    className={"gallery-delete-button"}>
                    {t("common:common.delete")}
                </Button>
                <Button
                    color={'orange'}
                    onClick={() => {
                        setCurrentImage(image);
                        setOpenEdit(true);
                    }}
                    className={"edit_button"}>
                    {t("common:common.edit")}
                </Button>
            </Button.Group>

            <img
                src={config.BASE_URL_IMAGES + 'all-images/' + image.link} alt={'image-' + index}
            />
        </Grid.Column>
    );

    let showSelectImages = selectImage.map((item, index) =>
        <Grid.Column key={index} width={5}>
            <img src={config.BASE_URL_IMAGES + 'all-images/' + item.link} alt={'image-' + index}
                 className={selectImage[index].selected ? 'selectedImage' : 'unselectedImage'}
                 style={item.link.includes(".webp") ? {
                     border: 'blueviolet',
                     borderStyle: 'solid',
                     borderWidth: '5px'
                 } : {}}
                 onClick={() => {
                     const newArray = [...selectImage];
                     newArray[index].selected = !selectImage[index].selected;
                     setSelectImage(newArray);
                     if (newArray[index].selected) {
                         setImages([...images, newArray[index]]);

                     } else {
                         deleteImage(newArray[index].link, id, setImages, setSelectImage, selectImage)
                     }
                 }}
            />
            <Icon
                className={selectImage[index].selected ? 'selectedLabel' : 'unselectedLabel'}
                name={selectImage[index].selected ? "check circle outline" : "circle outline"}
            />
        </Grid.Column>
    );

    const displayType = userStore.isAdmin ? "admin" : "user";

    if (type === "text") {
        return (
            <>
                <CarouselProvider
                    naturalSlideWidth={naturalSlideWidth}
                    naturalSlideHeight={naturalSlideHeight}
                    interval={interval}
                    totalSlides={texts.length}
                    infinite={infinite}
                    isPlaying={isPlaying}
                    style={style ? style : {}}>
                    <Slider>

                        {GetTextItems(texts, displayType, setIsEditTextItem, setItemToEdit, setTexts, id)}
                    </Slider>
                    {
                        texts.length > 1 && showButtons ?
                            <ButtonBack className={'padding-zero'} style={{
                                borderStyle: 'none', background: 'transparent', position: 'absolute',
                                left: '2%', top: '50%'
                            }}>
                                <Icon name={'angle left'} style={{margin: 'auto'}} inverted size={"small"} circular/>
                            </ButtonBack>
                            : null
                    }
                    {
                        texts.length > 1 && showButtons ?
                            <ButtonNext className={'padding-zero'} style={{
                                borderStyle: 'none', background: 'transparent', position: 'absolute',
                                right: '2%', top: '50%'
                            }}>
                                <Icon name={'angle right'} style={{margin: 'auto'}} inverted size={"small"} circular/>
                            </ButtonNext>
                            : null
                    }
                    {
                        texts.length > 1 && showDots ?
                            <DotGroup className={'slider-dots'}
                                      style={{textAlign: 'center'}}/>
                            : null
                    }
                </CarouselProvider>
                {displayType === "admin" &&
                    (<div className={'grid ui'}>
                        <div className={'row centered'}>
                            <div className={'column'}>
                                <Button style={{display: 'flex', margin: 'auto'}} circular icon={'plus'} color={'blue'}
                                        onClick={_ => {
                                            setAddNewText(true)
                                        }}/>
                            </div>
                        </div>
                        <Modal open={addNewText || isEditTextItem}
                               className={'margin-auto'}
                               centered={true}>
                            <Modal.Header>{t("common:common.select")}</Modal.Header>
                            <Modal.Content scrolling>
                                <div className={'grid ui'}>
                                    <div className={'row centered'}>
                                        <div className={'sixteen wide column'}>
                                            <Form>
                                                <Form.Field>
                                                    <div style={{display: "inline-flex"}}>
                                                        <label
                                                            style={{
                                                                margin: "auto",
                                                                marginRight: "5px"
                                                            }}>
                                                            <b>{t("common:common.language")}:</b>
                                                        </label>
                                                        <DropdownMenu name={"Select Language"}
                                                                      selector={true}
                                                                      defaultValue={isEditTextItem ? languages.LANGUAGES.map((language) => {
                                                                          if (language.languageCode === itemToEdit.language) {
                                                                              return language.language
                                                                          }
                                                                      }) : null}
                                                                      onChange={selection => {
                                                                          languages.LANGUAGES.map((language) => {
                                                                              if (language.language === selection) {
                                                                                  if (isEditTextItem) {
                                                                                      setItemToEdit({
                                                                                          ...itemToEdit,
                                                                                          language: language.languageCode
                                                                                      })
                                                                                  } else {
                                                                                      setNewTextItem({
                                                                                          ...newTextItem,
                                                                                          language: language.languageCode
                                                                                      })
                                                                                  }

                                                                              }
                                                                          })
                                                                      }}>
                                                            {languages.LANGUAGES.map((language, index) => (
                                                                <DropdownItem
                                                                    key={index}>{language.language}</DropdownItem>
                                                            ))
                                                            }
                                                        </DropdownMenu>
                                                    </div>
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Überschrift</label>
                                                    <input value={isEditTextItem ? itemToEdit.title : newTextItem.title}
                                                           onChange={e => {
                                                               if (isEditTextItem) {
                                                                   setItemToEdit({...itemToEdit, title: e.target.value})
                                                               } else {
                                                                   setNewTextItem({
                                                                       ...newTextItem,
                                                                       title: e.target.value
                                                                   })
                                                               }
                                                           }}/>
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Text</label>
                                                    <textarea
                                                        value={isEditTextItem ? itemToEdit.content : newTextItem.content}
                                                        onChange={e => {
                                                            if (isEditTextItem) {
                                                                setItemToEdit({...itemToEdit, content: e.target.value})
                                                            } else {
                                                                setNewTextItem({
                                                                    ...newTextItem,
                                                                    content: e.target.value
                                                                })
                                                            }
                                                        }}/>
                                                </Form.Field>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button content={t('common:common.cancel')} color={'red'} onClick={() => {
                                    setAddNewText(false);
                                    setIsEditTextItem(false);
                                }}/>
                                <Button color={'green'}
                                        disabled={isEditTextItem ? false : newTextItem.language === ''}
                                        content={isEditTextItem ? t('common:common.edit') : t('common:common.save')}
                                        onClick={() => {
                                            if (isEditTextItem) {
                                                updateTextItem(itemToEdit).then(indexToUpdate => {
                                                    let newTexts = texts;
                                                    newTexts[indexToUpdate] = itemToEdit;
                                                    setTexts(newTexts);
                                                    setIsEditTextItem(false);
                                                })


                                            } else {
                                                createNewTextItem(newTextItem).then(createdItem => {
                                                    setNewTextItem({...newTextItem, title: '', content: ''})
                                                    texts.push(createdItem)
                                                    setAddNewText(false)
                                                })
                                            }
                                        }}
                                />
                            </Modal.Actions>
                        </Modal>
                    </div>)}
            </>
        )
    } else {
        return (
            displayType === "admin" ?
                <Grid>
                    {EditImageModal()}
                    <Grid.Row>
                        {showImages}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <input type="file" id="file"
                                   onChange={e => uploadThumbnailImage(e, id, images, setIsSelectImageModalOpen, setImages, setSelectImage)}
                                   accept="image/*"
                                   multiple/>
                            <Button.Group>
                                <Button
                                    color={"orange"}
                                    onClick={() => setIsSelectImageModalOpen(true)}>
                                    {t("common:common.select")}
                                </Button>
                                <Button color={"teal"}>
                                    <label htmlFor="file">
                                        {t("common:common.upload")}
                                    </label>
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Modal open={isSelectImageModalOpen}
                           className={'margin-auto'}
                           centered={true}>
                        <Modal.Header>{t("common:common.select")}</Modal.Header>
                        <Modal.Content scrolling>
                            <Grid>
                                <Grid.Row>
                                    <div style={{display: 'flex', marginBottom: '20px'}}>
                                        <div style={{
                                            background: 'blueviolet',
                                            height: '10px',
                                            width: '10px',
                                            margin: 'auto 10px',
                                        }}/>
                                        <p>.webp</p>
                                    </div>
                                </Grid.Row>
                                <Grid.Row>
                                    {showSelectImages}
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative icon='delete' onClick={() => {
                                setSelectImage(prevSelectImage);
                                setIsSelectImageModalOpen(false)
                            }}/>
                            <Button
                                positive
                                icon='checkmark'
                                onClick={() => {
                                    addSelectedImages(images, setIsSelectImageModalOpen, id, setImages);
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                </Grid>
                : <CarouselProvider
                    naturalSlideWidth={naturalSlideWidth}
                    naturalSlideHeight={naturalSlideHeight}
                    interval={interval}
                    visibleSlides={2}
                    totalSlides={images.length}
                    infinite={infinite}
                    isPlaying={isPlaying}
                    style={style ? style : {}}>
                    <Slider>
                        {showImageSlides}
                    </Slider>
                    {
                        images.length > 1 && displayType !== "admin" && showButtons ?
                            <ButtonBack className={'padding-zero'} style={{
                                borderStyle: 'none', background: 'transparent', position: 'absolute',
                                left: '2%', top: '50%'
                            }}>
                                <Icon name={'angle left'} style={{margin: 'auto'}} inverted size={"small"} circular/>
                            </ButtonBack>
                            : null
                    }
                    {
                        images.length > 1 && displayType !== "admin" && showButtons ?
                            <ButtonNext className={'padding-zero'} style={{
                                borderStyle: 'none', background: 'transparent', position: 'absolute',
                                right: '2%', top: '50%'
                            }}>
                                <Icon name={'angle right'} style={{margin: 'auto'}} inverted size={"small"} circular/>
                            </ButtonNext>
                            : null
                    }
                    {
                        images.length > 1 && showDots ?
                            <DotGroup className={'slider-dots'}
                                      style={{position: 'absolute', bottom: 0, width: '100%', textAlign: 'center'}}/>
                            : null
                    }
                </CarouselProvider>
        )
    }

};

export default withTranslation(['common'])(ImageCarouselComponent)