import React, {useEffect, useState} from "react"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";


const DropdownMenu = (props) => {
    const {name, children, defaultValue, onChange = ()=>{}, selector, floated, secondary, icon, className=""} = props;
    const [selectedItem, setSelectedItem] = useState(defaultValue ? defaultValue : null);
    const [open, setOpen] = useState(false);
    const [contentClass, setContentClass] = useState(className ? "dropdown-content-closed "+className : "dropdown-content-closed background-secondary text-secondary")

    useEffect(() => {
        let _contentClass = "";
        if (open)
            _contentClass += "dropdown-content-open ";
        else
            _contentClass += "dropdown-content-closed ";
        if(className)
            _contentClass += className;
        if (secondary)
            _contentClass += "background-secondary text-secondary ";
        else
            _contentClass += "background-primary text-primary ";
        setContentClass(_contentClass);
    }, [open])

    return (
        <div
            style={floated ? {float: floated} : {}}
            key={"dropdown.div.0"}
            className="dropdown">
            <Button key={"dropdown.button.0"}
                    className={className ? className+" dropbtn" : secondary ? "dropbtn button-secondary" : "dropbtn button-primary"}
                    onClick={() => setOpen(!open)}>
                {selectedItem === null ?
                    name
                    : selectedItem}
                <Icon name={icon ? icon : "caret down"}/>
            </Button>
            <div key={"dropdown.div.1"}
                 className={contentClass}>
                {
                    children && children.length > 0 ?
                        children.map((item, index) => (
                            <div className={className ? "dropdown-item-admin admin-primary" : secondary ? "dropdown-item-secondary background-secondary" : "dropdown-item-primary background-primary"} key={`dropdown.div.2.${index}`} onClick={() => {
                                if (selector) {
                                    setSelectedItem(item.props.children)
                                    onChange(item.props.children)
                                }
                            }}>
                                {item}
                            </div>
                        ))
                        : <div/>
                }
            </div>
        </div>
    )
}

export default DropdownMenu